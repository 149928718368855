// import styled from "@emotion/styled";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// const SignupButton = styled.a`
//   font-size: 2em;
//   text-decoration: underline;
//   margin-bottom: 2rem;
//   display: block;
// `;

// markup
const Page = ({ location }) => {
  return (
    <Layout path={location.pathname} title="הרשמה">
      <SectionContent title="הרשמה">
        {/* <SignupButton
          title="הרשמה אולטרה עוטף עזה 2022"
          href="https://eventbuzz.co.il/lp/event/trmkb"
        >
          להרשמה למירוץ לחצו כאן
        </SignupButton> */}
        <p>
          <strong>הרשמה של יחידות צבא, דרך מנהלן ראשי , קאג</strong>
        </p>
        <p>
          <strong>הרשמה של חיילים בשרות פעיל, דרך ק.א.ג יחידתי</strong>
        </p>
        <StaticImage src="../images/ultraotefaza2022.jpg" title="הרשמה אולטרה עוטף עזה 2022" />
      </SectionContent>
    </Layout>
  );
};

export default Page;
